import React from "react"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact Us"
    />
    <div className="container top-heading">
        <ContactForm />
    </div>
  </Layout>
)

export default ContactPage